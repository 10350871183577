import { useState } from 'react'
import { useParams, usePathname } from 'next/navigation'
import { Button, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Link from 'next/link'

import { locales } from './locales'

const Country = ({ locale, isSelected, asPath, onClick }) => {
  const { code, label } = locale

  return (
    <Link href={`/${code}/${asPath}`} legacyBehavior passHref prefetch={false}>
      <MenuItem onClick={onClick} component="a">
        <span>{label}</span>
        {isSelected && (
          <CheckIcon
            sx={{
              ml: 'auto',
              color: 'var(--sm-primary-color)'
            }}
          />
        )}
      </MenuItem>
    </Link>
  )
}

export default function LanguageSelection() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const params = useParams()!
  const path = usePathname()!
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const asPath = path.replace(`/${params.lang}`, '') || '/'

  return (
    <>
      <Button
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        size={isMobile ? 'small' : 'large'}
        sx={{ mr: isMobile ? 0 : 1, maxHeight: 38 }}
      >
        {params.lang}
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        MenuListProps={{
          dense: isMobile
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{ top: 5 }}
      >
        {locales.map(_locale => (
          <Country
            key={_locale.label}
            locale={_locale}
            isSelected={_locale.code === params.lang}
            asPath={asPath}
            onClick={handleClose}
          />
        ))}
      </Menu>
    </>
  )
}
