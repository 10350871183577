'use client'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { createContext, useContext, useMemo, ReactNode } from 'react'

// Forms constants
export const FORMS_MIN = 0
const FORMS_MEDIUM = 50
export const FORMS_MAX = 100

export const FORMS = [
  { value: FORMS_MIN, label: `${FORMS_MIN}%` },
  { value: 25, label: `25%` },
  { value: FORMS_MEDIUM, label: `${FORMS_MEDIUM}%` },
  { value: 75, label: '75%' },
  { value: FORMS_MAX, label: `${FORMS_MAX}%` }
]

// Odds constants
export const ODDS_MIN = 1.01
const ODDS_MEDIUM = 2.5
export const ODDS_MAX = 5

export const ODDS_STEPS = [
  { value: ODDS_MIN, label: ODDS_MIN },
  { value: 2, label: 2 },
  { value: ODDS_MEDIUM, label: ODDS_MEDIUM },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: ODDS_MAX, label: `${ODDS_MAX}+` }
]

const FORMS_DEFAULT = [FORMS_MIN, FORMS_MAX]
const ODDS_DEFAULT = [ODDS_MIN, ODDS_MAX]

type FilterState = {
  value: number[]
  isDefault: boolean
}

type CombinedFilters = {
  forms: {
    home: FilterState
    away: FilterState
  }
  odds: {
    home: FilterState
    away: FilterState
  }
}

type SetFiltersFunction = (filters: {
  forms?: { home?: number[]; away?: number[] }
  odds?: { home?: number[]; away?: number[] }
}) => void

type URLParams = 'formsHome' | 'formsAway' | 'oddsHome' | 'oddsAway'

type DeleteFilterFunction = (filterKey: URLParams) => void

type CombinedContextType = [
  CombinedFilters,
  SetFiltersFunction,
  DeleteFilterFunction
]

const CombinedContext = createContext<CombinedContextType>([
  {
    forms: {
      home: { value: FORMS_DEFAULT, isDefault: true },
      away: { value: FORMS_DEFAULT, isDefault: true }
    },
    odds: {
      home: { value: ODDS_DEFAULT, isDefault: true },
      away: { value: ODDS_DEFAULT, isDefault: true }
    }
  },
  () => {},
  () => {}
])

export function useCombinedFilters() {
  return useContext(CombinedContext)
}

export function CombinedProvider({ children }: { children: ReactNode }) {
  const searchParams = useSearchParams()!
  const pathname = usePathname()!
  const { push } = useRouter()

  const updateURL = (newParams: URLSearchParams) => {
    const newURL = newParams.toString()
      ? `${pathname}?${newParams.toString()}`
      : pathname
    push(newURL)
  }

  const setFilters: SetFiltersFunction = ({
    forms = { home: FORMS_DEFAULT, away: FORMS_DEFAULT },
    odds = { home: ODDS_DEFAULT, away: ODDS_DEFAULT }
  }) => {
    const newSearchParams = new URLSearchParams(searchParams)

    const updateParam = (
      key: URLParams,
      value: number[],
      defaultValue: number[]
    ) => {
      if (JSON.stringify(value) !== JSON.stringify(defaultValue)) {
        newSearchParams.set(key, value.join('-'))
      } else {
        newSearchParams.delete(key)
      }
    }

    updateParam('formsHome', forms.home, FORMS_DEFAULT)
    updateParam('formsAway', forms.away, FORMS_DEFAULT)
    updateParam('oddsHome', odds.home, ODDS_DEFAULT)
    updateParam('oddsAway', odds.away, ODDS_DEFAULT)

    updateURL(newSearchParams)
  }

  const deleteFilter: DeleteFilterFunction = filterKey => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete(filterKey)
    updateURL(newSearchParams)
  }

  const getFilterState = (
    paramName: string,
    defaultValue: number[]
  ): FilterState => {
    const param = searchParams.get(paramName)
    if (!param) return { value: defaultValue, isDefault: true }

    const value = param.split('-').map(Number)
    return {
      value,
      isDefault: JSON.stringify(value) === JSON.stringify(defaultValue)
    }
  }

  const filters: CombinedFilters = {
    forms: {
      home: getFilterState('formsHome', FORMS_DEFAULT),
      away: getFilterState('formsAway', FORMS_DEFAULT)
    },
    odds: {
      home: getFilterState('oddsHome', ODDS_DEFAULT),
      away: getFilterState('oddsAway', ODDS_DEFAULT)
    }
  }

  const value = useMemo<CombinedContextType>(
    () => [filters, setFilters, deleteFilter],
    [filters, searchParams]
  )

  return (
    <CombinedContext.Provider value={value}>
      {children}
    </CombinedContext.Provider>
  )
}
