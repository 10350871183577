'use client'
import { createContext, useContext, useMemo, useState } from 'react'
import useQuery from 'swr'
import { api } from '../../../utils/api'

const LeaguesFiltersContext = createContext({
  leagues: [],
  data: [],
  onSelectLeague() {}
})

async function fetchData() {
  const data = await api('leagues', {
    searchParams: {
      current: true
    }
  }).json()

  return data
}

export function LeaguesFiltersProvider({ children }) {
  const [leagues, setLeagues] = useState([])
  const { data = [] } = useQuery(['leagues/all'], () => fetchData())

  function onSelectLeague(leagues) {
    setLeagues(leagues)
  }

  const value = useMemo(
    () => ({
      leagues,
      onSelectLeague,
      data
    }),
    [data, leagues]
  )

  return (
    <LeaguesFiltersContext.Provider value={value}>
      {children}
    </LeaguesFiltersContext.Provider>
  )
}

export function useLeaguesFilters() {
  return useContext(LeaguesFiltersContext)
}
