'use client'
import { createTheme } from '@mui/material/styles'
import { yellow } from '@mui/material/colors'

export const theme = createTheme({
  unstable_strictMode: true,
  components: {
    MuiPaper: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiSlider: {
      styleOverrides: {
        // thumb: {
        //   fontFamily: 'SmartScoreNumbers, sans-serif'
        // },
        valueLabel: {
          fontFamily: 'SmartScoreNumbers, sans-serif'
        },
        markLabel: {
          fontFamily: 'SmartScoreNumbers, sans-serif'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '90%'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          overflow: 'unset'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowY: 'scroll',
          fontSize: '1rem',
          backgroundColor: 'var(--sm-body-bg-color)',
          '@media (max-width: 899.95px)': {
            marginBottom: '58px'
          }
        }
      }
    }
  },
  typography: {
    fontFamily:
      '-apple-system, sustem-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, "Apple Color Emoji","Segoe UI Emoji"'
  },
  palette: {
    primary: {
      main: '#00a991'
    },
    favorite: {
      main: yellow[700]
    }
  }
})
