'use client'
import Container from '@mui/material/Container'
import { useParams, usePathname, useSearchParams } from 'next/navigation'
import React from 'react'
import { ROUTES } from '../../utils/routes'
import { useMediaQuery } from '@mui/material'
import { theme } from '../../components/theme'
import dynamic from 'next/dynamic'

const BottomNavigationMenu = dynamic(
  () => import('../../components/bottomNavigation/BottomNavigation'),
  { ssr: false }
)

const Search = dynamic(() => import('../../components/filters/Search/Search'))

export function RootContainer({ children }: { children: React.ReactNode }) {
  let pathname = usePathname()
  const param = useParams()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isOpenSearch = useSearchParams()?.has('search')

  if (param!.lang && typeof param!.lang == 'string') {
    pathname = pathname!.replace(`/${param!.lang}`, '')
    if (pathname == '') pathname = '/'
  }

  const IS_ABOUT_PAGE = pathname === ROUTES.about

  const IS_HOME_PAGE = pathname === ROUTES.home
  const CAN_SEARCH = [
    ROUTES.home,
    ROUTES.league,
    ROUTES.leagues,
    ROUTES.match,
    ROUTES.team
  ].some(route => {
    if (IS_HOME_PAGE) return true
    return pathname!.replace(/\//, '').includes(route)
  })

  return (
    <Container
      maxWidth={CAN_SEARCH ? 'xl' : false}
      component="main"
      disableGutters={IS_ABOUT_PAGE}
      sx={{
        minHeight: 'var(--sm-page-height)',
        mb: CAN_SEARCH ? 1 : 0,
        pr: CAN_SEARCH ? 2 : 0,
        pl: CAN_SEARCH ? 2 : 0,

        ['@media (max-width: 959.95px)']: {
          pr: 0,
          pl: 0
        }
      }}
    >
      {children}
      {CAN_SEARCH && isOpenSearch && <Search />}
      {isMobile && <BottomNavigationMenu />}
    </Container>
  )
}
