import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/projects/frontend/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/material/CssBaseline/CssBaseline.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/node_modules/@mui/material/styles/styled.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/material/styles/ThemeProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/node_modules/@mui/material/styles/ThemeProviderWithVars.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/material/styles/useTheme.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/material/styles/useThemeProps.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/styled-engine/GlobalStyles/GlobalStyles.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/styled-engine/StyledEngineProvider/StyledEngineProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/system/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/system/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/system/createBox/createBox.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/system/cssVars/useCurrentColorScheme.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/node_modules/@mui/system/GlobalStyles/GlobalStyles.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/node_modules/@mui/system/Grid/Grid.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/node_modules/@mui/system/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/system/ThemeProvider/ThemeProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/node_modules/@mui/system/useMediaQuery/useMediaQuery.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/node_modules/@mui/system/useTheme/useTheme.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/system/useThemeProps/useThemeProps.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/system/useThemeWithoutDefault/useThemeWithoutDefault.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/utils/esm/useControlled/useControlled.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/utils/esm/useEnhancedEffect/useEnhancedEffect.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/utils/esm/useEventCallback/useEventCallback.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/utils/esm/useForkRef/useForkRef.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/utils/esm/useId/useId.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/utils/esm/useLazyRef/useLazyRef.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/utils/esm/useOnMount/useOnMount.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/utils/esm/usePreviousProps/usePreviousProps.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/projects/frontend/node_modules/@mui/utils/esm/useSlotProps/useSlotProps.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/node_modules/@mui/utils/esm/useTimeout/useTimeout.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/public/static/about/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/public/static/about/twitter.svg");
;
import(/* webpackMode: "eager", webpackExports: ["RootContainer"] */ "/vercel/path0/projects/frontend/src/app/[lang]/container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FavoritesProvider"] */ "/vercel/path0/projects/frontend/src/components/filters/stores/favorites.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["CombinedProvider"] */ "/vercel/path0/projects/frontend/src/components/filters/stores/filters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LeaguesFiltersProvider"] */ "/vercel/path0/projects/frontend/src/components/filters/stores/leagues.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/src/components/footer/components/socialLinks.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderBar"] */ "/vercel/path0/projects/frontend/src/components/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/vercel/path0/projects/frontend/src/components/theme.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/src/styles.global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/projects/frontend/src/components/footer/footer.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/vercel/path0/projects/frontend/src/utils/i18n.tsx");
