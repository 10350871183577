// import { useState } from 'react'
'use client'
import Link from 'next/link'

import {
  Toolbar,
  Button,
  Typography,
  IconButton,
  AppBar,
  useTheme,
  useMediaQuery,
  Container
} from '@mui/material'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import SearchIcon from '@mui/icons-material/Search'

import { useTranslation } from '../../utils/i18n'

import { ROUTES } from '../../utils/routes'
import { useAuth } from '../auth/hooks'
import s from './header.module.css'
import LanguageSelection from './languageSelection/LanguageSelection'

import { getNormalCountryFlag } from '../../utils/mordor'

const logoContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  whiteSpace: 'nowrap',
  ':visited, :link': {
    color: 'white'
  },
  span: {
    color: 'var(--sm-primary-color)'
  },
  '@media (max-width: 400px)': {
    fontSize: '13px'
  }
}

export function HeaderBar() {
  const { t } = useTranslation('common.header')
  const searchParams = useSearchParams()!
  const pathname = usePathname()!

  return (
    <AppBar
      sx={{
        backgroundColor: 'var(--sm-header-bg-color)',
        padding: '0',
        contain: 'layout'
      }}
      position="sticky"
      variant="elevation"
    >
      <Container maxWidth={'xl'}>
        <Toolbar style={{ alignItems: 'initial' }} disableGutters>
          {/* Logo */}
          <Link
            href={{
              pathname: ROUTES.home + searchParams.toString()
            }}
            prefetch={false}
            passHref
            legacyBehavior
          >
            <Typography variant="h6" sx={logoContainerStyles} component="a">
              SmartScore&nbsp;
              <span>365&nbsp;{getNormalCountryFlag('UA')}BETA</span>
            </Typography>
          </Link>

          {/* Navigation */}
          <nav className={s.navigation}>
            <Link
              href={{
                pathname: ROUTES.home + searchParams.toString()
              }}
              passHref
              legacyBehavior
              prefetch={false}
            >
              <Button
                className={
                  ROUTES.home === pathname || pathname!.includes(ROUTES.match)
                    ? s.active
                    : ''
                }
              >
                {t('matches')}
              </Button>
            </Link>

            <Link href={ROUTES.about} passHref legacyBehavior prefetch={false}>
              <Button className={pathname === ROUTES.about ? s.active : ''}>
                {t('about')}
              </Button>
            </Link>
          </nav>

          {/* Auth */}
          <section className={s.btns}>
            <Search />
            <LanguageSelection />
            <AuthBlock />
          </section>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

function Search() {
  const theme = useTheme()
  const { t } = useTranslation('common.header')
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const router = useRouter()
  const searchParams = useSearchParams()!

  function onClick() {
    const newSearchParams = new URLSearchParams(searchParams.toString())
    newSearchParams.set('search', 'true')
    router.push(`?${newSearchParams.toString()}`)
  }

  return isMobile ? (
    <IconButton color="primary" onClick={onClick} size="small">
      <SearchIcon />
    </IconButton>
  ) : (
    <Button
      // variant="outlined"
      startIcon={<SearchIcon />}
      sx={{ mr: 1 }}
      size="large"
      onClick={onClick}
    >
      {t('search')}
    </Button>
  )
}

const AuthBlock = () => {
  const theme = useTheme()
  const { t } = useTranslation('common.header')
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { user, isLoading } = useAuth()
  const tabel = isLoading ? 'loading.' : user ? t('logout') : t('login')
  const Icon = user ? LogoutIcon : LoginIcon

  return isMobile ? (
    <Link
      href={user ? ROUTES.logout : ROUTES.login}
      legacyBehavior
      passHref
      prefetch={false}
    >
      <IconButton
        size="small"
        sx={
          isLoading
            ? { color: 'transparent !important' }
            : {
                color: 'var(--sm-primary-color) !important'
              }
        }
      >
        <Icon />
      </IconButton>
    </Link>
  ) : (
    <Link
      href={user ? ROUTES.logout : ROUTES.login}
      legacyBehavior
      passHref
      prefetch={false}
    >
      <Button
        // variant="outlined"
        size="large"
        sx={
          isLoading
            ? { color: 'transparent !important', maxWidth: '107.45px' }
            : {
                color: 'var(--sm-primary-color) !important'
              }
        }
        startIcon={<Icon />}
      >
        {tabel}
      </Button>
    </Link>
  )
}
